.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

.no-shadow header,
.no-shadow footer {
  box-shadow: none !important;
}

.header,
.footer {
  height: 55px;
  min-width: 320px;
  max-width: 767px;
  margin: 0 auto;
  background-color: #ffffff;
}

.content {
  position: relative;
  flex-grow: 1;
  padding-top: 60px;
  padding-bottom: 60px;
  /* min-width: 320px;
  max-width: 767px; */
  margin: 0 auto;
}

.no-padding-top {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.no-scroll {
  overflow: hidden;
}

.overlay::before {
  content: '';
  position: fixed;
  top: 55px;
  left: 0;
  right: 0;
  bottom: 60px;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
  pointer-events: none;
}

body,
.content {
  -ms-overflow-style: none;
}

body::-webkit-scrollbar,
.content::-webkit-scrollbar {
  display: none;
}

body:focus,
.content:focus {
  scrollbar-width: thin;
  -ms-overflow-style: auto;
}

body::-webkit-scrollbar,
.content::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

body::-webkit-scrollbar-thumb,
.content::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

body::-webkit-scrollbar-thumb:hover,
.content::-webkit-scrollbar-thumb:hover {
  background: #555;
}
