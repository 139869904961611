body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
  line-height: 1.5;
  background-color: #f8f9fa;
  display: flex;
  /* flex-direction: column; */
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

* {
  box-sizing: border-box;
}

#root {
  min-width: 320px;
  max-width: 767px;
  overflow-x: hidden;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

header,
footer {
  min-width: 320px;
  max-width: 767px;
  margin: 0 auto;
  background-color: #ffffff;
  padding: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  flex-shrink: 0;
}

button,
input,
select,
textarea {
  font-size: 1rem;
  padding: 0.5rem;
  margin: 0.5rem 0;
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 4px;
}

button {
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;
}

button:hover {
  background-color: #0056b3;
}
